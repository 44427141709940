<template>
  <b-row>
    <b-col md="2">
      <b-form-group
        label="Challan Number"
      >
        <b-form-input
          v-model="challanNumber"
          type="text"
          placeholder="Search Challan Number"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Received Date Range"
      >
        <flat-picker
          v-model="receivedDateRange"
          placeholder="Select Received Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Buyers"
      >
        <custom-v-select
          v-model="selectedBuyers"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="buyersOptions"
          label="text"
          placeholder="Select Buyers"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Return Received Status"
      >
        <custom-v-select
          v-model="selectedReceivedStatus"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="receivedStatusOptions"
          label="text"
          placeholder="Select Received Status"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group label="Created By">
        <custom-v-select
          v-model="selectedUsers"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="usersList"
          placeholder="Select Created By"
          multiple
          :close-on-select="false"
          label="fullname"
          :reduce="(data => data.id)"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Created Date Range"
      >
        <flat-picker
          v-model="createdDateRange"
          placeholder="Select Created Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group label="Returned To">
        <custom-v-select
          v-model="selectedSalesmans"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="salesmanOptions"
          placeholder="Select Salesman"
          multiple
          :close-on-select="false"
          label="text"
          :reduce="(data => data.id)"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    CustomVSelect,
    BFormInput,
    FlatPicker,
  },
  data() {
    return {
      challanNumber: null,
      receivedDateRange: null,
      buyersOptions: [],
      salesmanOptions: [],
      selectedBuyers: null,
      receivedStatusOptions: [
        { value: 'YES', text: 'Yes' },
        { value: 'NO', text: 'No' },
        { value: 'N/A', text: 'Not Applicable' },
      ],
      selectedReceivedStatus: null,
      usersList: [],
      selectedUsers: [],
      selectedSalesmans: [],
      createdDateRange: null,
    }
  },
  watch: {
    receivedDateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
    createdDateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/accounts/buyers_options')
        .then(res => {
          this.buyersOptions = res.data.data
        })

      axios.get('/delivery/users_list')
        .then(res => {
          this.usersList = res.data
        })

      axios.get('/accounts/salesmans_options')
        .then(res => {
          this.salesmanOptions = res.data.data
        })
    },
    filtersChangeHandler() {
      const filtersdata = {}

      if (this.challanNumber) {
        filtersdata.challan_number = this.challanNumber
      }

      if (this.receivedDateRange) {
        const [dateFrom, dateTo] = this.receivedDateRange.split(' to ')
        if (dateFrom && dateTo) {
          filtersdata.received_date_from = dateFrom
          filtersdata.received_date_to = dateTo
        }
      }

      if (this.createdDateRange) {
        const [createdDateFrom, createdDateTo] = this.createdDateRange.split(' to ')
        if (createdDateFrom && createdDateTo) {
          filtersdata.created_date_from = createdDateFrom
          filtersdata.created_date_to = createdDateTo
        }
      }

      if (this.selectedBuyers) {
        filtersdata.buyers = this.selectedBuyers.map(item => item.id)
      }

      if (this.selectedReceivedStatus) {
        filtersdata.received_return = this.selectedReceivedStatus.value
      }

      if (this.selectedUsers) {
        filtersdata.created_by = this.selectedUsers
      }
      if (this.selectedSalesmans) {
        filtersdata.returned_to = this.selectedSalesmans
      }
      this.$emit('filtersUpdated', filtersdata)
    },
  },
}
</script>
